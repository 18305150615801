const DEFAULT_HEAD_START_MESSAGE = "Have a question? Connect with someone at our store.";
const OPTIN_MESSAGE_1 = "I authorize";
const OPTIN_MESSAGE_2 = "to send promotional and informational text messages, which may be delivered through automated systems, to the number provided.";
const OPTIN_MESSAGE_3 = "Message & data rates apply.";
const CHAT_ICON =
  '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" enable-background="new 0 0 512 512" xml:space="preserve"><path d="M256,0C114.6,0,0,85.9,0,192c0,75,57.5,139.8,141.1,171.4L85.3,512l160.5-128.4c3.4,0.1,6.7,0.4,10.2,0.4  c141.4,0,256-85.9,256-192C512,85.9,397.4,0,256,0z"/></svg>';
const VIDEO_ICON =
  '<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 128 128"><defs></defs><title>x</title><path class="cls-1" d="M125.83556,87.42125a3.48687,3.48687,0,0,1-3.43167,3.49294H108.05205L93.492,79.3813V89.70086A8.85548,8.85548,0,0,1,84.64306,98.562H11.01333a8.85548,8.85548,0,0,1-8.84889-8.86116V38.29907A8.85542,8.85542,0,0,1,11.01333,29.438H84.64306A8.85542,8.85542,0,0,1,93.492,38.29907v10.246l14.5969-11.45936h14.315a3.44713,3.44713,0,0,1,3.43167,3.41941Z"/></svg>';
const SERVICE_ICON =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M352 320c88.4 0 160-71.6 160-160c0-15.3-2.2-30.1-6.2-44.2c-3.1-10.8-16.4-13.2-24.3-5.3l-76.8 76.8c-3 3-7.1 4.7-11.3 4.7H336c-8.8 0-16-7.2-16-16V118.6c0-4.2 1.7-8.3 4.7-11.3l76.8-76.8c7.9-7.9 5.4-21.2-5.3-24.3C382.1 2.2 367.3 0 352 0C263.6 0 192 71.6 192 160c0 19.1 3.4 37.5 9.5 54.5L19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L297.5 310.5c17 6.2 35.4 9.5 54.5 9.5zM80 408a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/></svg>';
const SEND_SVG =
  '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 18" version="1.1"><title>send</title><g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Rounded" transform="translate(-374.000000, -1529.000000)"><g id="Content" transform="translate(100.000000, 1428.000000)"><g id="-Round-/-Content-/-send" transform="translate(272.000000, 98.000000)"><g><polygon id="Path" points="0 0 24 0 24 24 0 24"/><path d="M3.4,20.4 L20.85,12.92 C21.66,12.57 21.66,11.43 20.85,11.08 L3.4,3.6 C2.74,3.31 2.01,3.8 2.01,4.51 L2,9.12 C2,9.62 2.37,10.05 2.87,10.11 L17,12 L2.87,13.88 C2.37,13.95 2,14.38 2,14.88 L2.01,19.49 C2.01,20.2 2.74,20.69 3.4,20.4 Z" id="icon-color" fill="#1D1D1D"/></g></g></g></g></g></svg>';

export const getWidgetTemplate = () => `
<div id="snapcell_widget" class="remove-all-styles">
<div class="snapcell_prompt_message_bubble">
<div class="snapcell_prompt_close_button">
<button class="snapcell_prompt_close_button_button">X</button>
</div>
<div class="snapcell_prompt_card">
<div class="snapcell_prompt_text">
Hey there, would you like to chat with a sales executive now?
</div>
</div>
</div>
<div id="snapcell_content_chat" class="snapcell_content">
<div class="snapcell-chat-close" id="snapcell_chat_close_1">X</div>
<div class="snapcell_header" id="id_snapcell_header">
<span id="snapcell_header_icon">
</span>
<span class="snapcell_header_text">
</span>
</div>
<div class="snapcell_main_content">
<div class="snapcell_headline">
${DEFAULT_HEAD_START_MESSAGE}
</div>
<div class="snapcell_message_holder">
<div class="snapcell_message_content"></div>
<div id="snapcell_message_content_success_error" class="snapcell_form_content snapcell_message_content_success_error">Testando</div>
</div>
<div class="snapcell_message_loading" style="display: none">
<div class="dot-pulse"></div>
</div>
<form id="snapcell_select_location" action="javascript:void(0);">
<div class="snapcell_form_content">
<div class="snapcell_input_content snapcell_input_content_100 snapcell_required">
<select class="snapcell_select snapcell_input_content_100" id="snapcell_location" name="location" placeholder="Select location"><option value="">Select location</option></select>
</div>
</div>
<br />
<div id="opt_in_disclaimer" name="opt_in_disclaimer">
<p class="optin">
${OPTIN_MESSAGE_1}
</p> 
<h2 class="optin opt_store" id="store_name"></h2> 
<p class="optin">
${OPTIN_MESSAGE_2}
${OPTIN_MESSAGE_3}
</p>
</div>
</form>
<form id="snapcell_sms_video_sent" action="javascript:void(0);">
<input id="snapcell_first_name_hide"  name="first_name" type="hidden" placeholder="First Name"></input>
<input id="snapcell_last_name_hide"  name="last_name" type="hidden" placeholder="Last Name"></input>
<input id="snapcell_name_hide"  name="name" type="hidden" placeholder="Name"></input>
<input id="snapcell_telephone"  name="telephone"  type="hidden" placeholder="Mobile"></input>
<input id="snapcell_department_sms_video"  name="department"  type="hidden" placeholder="Department"></input>
<input id="snapcell_vehicle_of_interest" name="vehicle_of_interest" type="hidden" placeholder="Message" />
<div class="snapcell_form_content snapcell_form_content--initial">
<div class="snapcell_input_content snapcell_input_content_100 snapcell_required">
<label id="snapcell_input_label_name_sms" for="snapcell_first_name_sms">Name</label>
<input id="snapcell_first_name_sms" type="text" name="snapcell_first_name_sms">
<div class="snapcell_input_border"></div>
</div>
<div class="snapcell_input_content snapcell_input_content_100 snapcell_required">
<label id="snapcell_input_label_phone_sms" for="snapcell_phone_sms">Mobile</label>
<input id="snapcell_phone_sms" type="text" name="snapcell_phone_sms">
<div class="snapcell_input_border"></div>
</div>
<div class="snapcell_input_content snapcell_input_content_100 snapcell_required">
<label id="snapcell_input_label_message_sms" for="message">Message*</label>
<input id="snapcell_message_sms" type="text" name="message_sms">
<div class="snapcell_input_border"></div>
</div>
<select class="snapcell_select snapcell_required snapcell_error snapcell_input_content_100" id="snapcell_department" name="department" placeholder="Select department"><option value="">Select a department</option></select>
</div>
<div class="snapcell_button_actions_sms">
<button id="snapcell_send_chat_button_sms">
<span class="snapcell_button_text">Connect via SMS</span>
<span class="snapcell_loader"></span>
</button>
<button id="snapcell_send_chat_button_video">
<span class="snapcell_button_text">Video Chat Live with Us</span>
<span class="snapcell_loader"></span>
</button>
<a id="snapcell_mess_link_button" class="snapcell_mess_link" href="javascript:void(0)">Message</a>
</div>
<div id="opt_in_disclaimer" name="opt_in_disclaimer">
<p class="optin">
${OPTIN_MESSAGE_1}
</p> 
<h2 class="optin opt_store" id="store_name"></h2> 
<p class="optin">
${OPTIN_MESSAGE_2}
${OPTIN_MESSAGE_3}
</p>
</div>
</form>
<form id="snapcell_message_sent" action="javascript:void(0);">
<input id="snapcell_first_name_hide"  name="first_name" type="hidden" placeholder="First Name"></input>
<input id="snapcell_type"  name="type" type="hidden" value="chat"></input>
<input id="snapcell_last_name_hide"  name="last_name" type="hidden" placeholder="Last Name"></input>
<a class="snapcell_sms_link snapcell_box" id="snapcell_mess_sales_button" href="javascript:void(0)">
<span class="snap_cell_icon">
${CHAT_ICON}
</span>
<span class="snap_cell_text">
Live Chat with
<strong>
Sales
</strong>
</span>
</a>
<a class="snapcell_sms_link snapcell_box" id="snapcell_mess_service_button" href="javascript:void(0)">
<span class="snap_cell_icon">
${SERVICE_ICON}
</span>
<span class="snap_cell_text">
Live Chat with
<strong>
Service
</strong>
</span>
</a>
<a class="snapcell_sms_link snapcell_box" id="snapcell_mess_link_button1" href="javascript:void(0)">
<span class="snap_cell_icon">
${CHAT_ICON}
</span>
<span class="snap_cell_text">
Chat with us using
<strong>
SMS
</strong>
</span>
</a>
<a class="snapcell_sms_video_link snapcell_box" id="snapcell_mess_link_button2" href="javascript:void(0)">
<span class="snap_cell_icon">
${VIDEO_ICON}
</span>
<span class="snap_cell_text">
Chat with us using
<strong>
Video
</strong>
</span>
</a>
<div id="opt_in_disclaimer" name="opt_in_disclaimer">
<p class="optin">
${OPTIN_MESSAGE_1}
</p> 
<h2 class="optin opt_store" id="store_name"></h2> 
<p class="optin">
${OPTIN_MESSAGE_2}
${OPTIN_MESSAGE_3}
</p>
</div>
</form>
<div class="snapcell_text_" id="snapcell_message_sent2">
</div>
</div>
<div class="snapcell_footer">
<form id="snapcell_message_chat" action="javascript:void(0);">
<div class="snapcell_form_content snapcell_form_content_small">
<div class="snapcell_input_content snapcell_required">
<input id="message_chat" type="text" name="message_chat" placeholder="Type your message here">
</div>
<div class="snapcell_button_submit">
<button type="submit">
${SEND_SVG}
</button>
</div>
</div>
<div id="opt_in_disclaimer" name="opt_in_disclaimer">
<p class="optin">
${OPTIN_MESSAGE_1}
</p> 
<h2 class="optin opt_store" id="store_name"></h2> 
<p class="optin">
${OPTIN_MESSAGE_2}
${OPTIN_MESSAGE_3}
</p>
</div>
</form>
<a href="https://snapcell.us.com/" target="_blank" class="snapcell_powered_by">
<span>Powered By:</span>
<img id="snapcell-logo" src="https://assets.snapcell.us.com/snapcell-logo-2020.png" />
</a>
</div>
</div>
<div id="snapcell_container_inside">
<form id="snapcell_chat_form">
<div id="store_name_container">
<h2 id="store_name"></h2>
</div>
<div id="notice"></div>
<div id="error"></div>
<div id="prompt">
<b>Have a question?</b>
<b>Connect with someone at our store.</b>
</div>
<div id="error_form"><b>Name, Mobile and Message are required.</b></div>
<input id="name" name="name" type="text" placeholder="Name"></input><br/>
<input id="first_name"  name="first_name" type="hidden" placeholder="First Name"></input>
<input id="last_name"  name="last_name" type="hidden" placeholder="Last Name"></input>
<input id="telephone"  name="telephone"  type="text" placeholder="Mobile"></input><br/>
<textarea id="vehicle_of_interest" name="vehicle_of_interest" type="text" placeholder="Message"></textarea><br/>
<select class="snapcell_select" id="department" name="department" placeholder="Select department"><option value="">Select a department</option></select>
<input id="send1" type="button" value="Connect via SMS"></button>
<input class="video_chat" id="send" type="button" value="Live Video Chat Now"></button>
<div id="opt_in_disclaimer" name="opt_in_disclaimer" type="text">
*By submitting you agree to receive text messages at the number provided. Message & data rates apply.
</div>
<div id="opt_in_disclaimer" name="opt_in_disclaimer">
<p class="optin">
${OPTIN_MESSAGE_1}
</p> <h2 class="optin opt_store" id="store_name"></h2> <p class="optin">
${OPTIN_MESSAGE_2}
${OPTIN_MESSAGE_3}
</p>
</div>
</form>
<div class="snapcell-chat-close" id="snapcell_chat_close_2">X</div>
<a href="https://snapcell.us.com/" target="_blank" class="powered-by">
<span>Powered By:</span>
<img id="snapcell-logo" src="https://assets.snapcell.us.com/snapcell-logo-2020.png"></img>
</a>
</div>
<div id="snapcell_chat" class="chat-animation">
<div class="snapcell_close_button"><img src="https://assets.snapcell.us.com/files/cancel_close_delete_icon.svg"></div>
</div>
<div id="snapcell_chat_close">
<div class="snapcell_chat_close_button" id="snapcell_chat_close_button_">X</div>
</div>
<audio id="chimesound" src="https://assets.snapcell.us.com/chime.wav" preload="true" />
</div>
`;

export const getStyles = (defaultColor: string, defaultColorRgb: string) => {
  return `
  @import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,900);
  #snapcell_widget * {box-sizing: content-box}
  #snapcell_widget {z-index: 9999; position: fixed; bottom: 50px; right: 50px;};
  #snapcell_chat_close { position: fixed; bottom: 140px; left: 140px; display: none; }
  #snapcell_chat_close.snapcell_chat_close_right { right: 57px; }
  #snapcell_chat {position: fixed; bottom: 12px; right: 12px; border: 3px solid #f29052; border-radius: 50%; background-image: url('./cta.png'); background-size: cover; color: transparent; cursor: pointer; width: 55px; height: 55px; line-height: 55px; text-align: center; font-family: 'Source Sans Pro'; font-weight: 800; font-size: 20px; transform: scale(1); background-color: white; }
  #snapcell_chat.hover { opacity: 0.9; }
  #snapcell_chat.chat-animation {animation: pulse-chat 2s infinite;}
  .snapcell_chat_left {left:  12px;}
  #snapcell_container_inside {position: fixed; background: transparent; bottom: 120px; right: 80px; height: auto; width:  250px; display: none; font-size: 14px; font-family: 'Source Sans Pro'; }
  #snapcell_chat_form {border-radius: 25px; padding: 10px; }
  #notice {display: none; min-height: 140px; padding: 10px; background: white; padding: 10px !important; border-radius: 5px !important; border: solid white 1px !important; }
  #error {display: none; min-height: 140px; padding: 10px; background: white; padding: 10px !important; border-radius: 5px !important; border: solid white 1px !important; }
  #snapcell_container_inside input, #snapcell_container_inside textarea {padding: 5px !important; border-radius: 5px !important; border: solid white 1px !important; }
  #snapcell_chat_form > textarea, #snapcell_chat_form > textarea::placeholder {font-family: 'Source Sans Pro' !important; font-size: 14px !important; }
  .text_widget_display_right {bottom: 50px; right: 50px;}
  .text_widget_display_left {bottom: 50px; left: 50px;}
  .snapcell_content_display_right {position:fixed; bottom: 80px; right: 35px; z-index: 10;}
  .snapcell_content_display_left {position:fixed; bottom: 80px; left: 35px; z-index: 10;}
  #snapcell_chat_form {background: orange;}
  #snapcell_chat_form > input[type=text] {all: revert; margin-top: 8px !important; margin-left: 8px !important; width: calc(100% - 30px); }
  #snapcell_chat_form > textarea {all: revert; margin-top:  8px !important; margin-left: 8px !important; width: calc(100% - 30px); height: 60px; }
  #snapcell_chat_form > select { display: none; width: 205px; padding: 5px 3px; margin: 8px 8px 0 !important; border: 0; border-radius: 4px; text-transform: capitalize; font-size: 12px;}
  #snapcell_chat_form #send {background: white; font-size: 12px; padding: 6px 14px; margin-top: 5px !important; }
  #snapcell_chat_form > input[type=button] {all: revert; margin-top: 8px !important; margin-left: 8px !important; margin-bottom: 8px !important; padding: 6px 4px !important; font-size: 11px;}
  #snapcell_chat_form > input[type=button].video_chat {all: revert; margin-top: 8px !important; margin-left: 0px !important; margin-bottom: 8px !important; padding: 6px 4px !important; font-size: 11px !important; }
  #snapcell_chat_form > input[type=button].video_chat.only {margin-left: 8px !important;}
  #opt_in_disclaimer {font-size: .7em; color: white; padding: 8px; line-height: 1.5em; font-style: oblique; }
  .snapcell-chat-close { background: #f29052; color: white; cursor: pointer; border-radius: 50%; display: flex; align-items: center; justify-content: center; position: absolute; top: -20px; right: -25px; font-family: 'Source Sans Pro'; font-weight: 800; font-size: 15px; box-shadow: 1px 3px 10px 1px rgba(0,0,0,0.5); height: 25px; width: 25px; display:none;}
  .snapcell_chat_close_button {background: gray; color: white; cursor: pointer; border-radius: 50%; display: flex; align-items: center; justify-content: center; position: absolute; top: 80px; right: -25px; font-family: 'Source Sans Pro'; font-weight: 800; font-size: 15px; box-shadow: 1px 3px 10px 1px rgba(0,0,0,0.5); height: 25px; width: 25px;}
  .snapcell_chat_message {color: #48484a; font-weight: 600;}
  .snapcell-chat-link {color: #f29052; font-weight: 600; text-decoration: none;}
  .live_chat_widget_display_right {bottom: 50px; right:  50px; }
  .live_chat_widget_display_left {bottom: 50px; left:   50px; }
  #snapcell_widget.live_chat_widget_display_right #snapcell_container_inside {right: 100px}
  #snapcell_widget.live_chat_widget_display_left #snapcell_container_inside {left: 100px}
  #snapcell_widget.live_chat_widget_display_left .snapcell-chat-close {right: 0; left: -25px}
  #snapcell_widget.live_chat_widget_display_left .powered-by {margin-left: auto; justify-content: flex-end;}
  #snapcell_widget .powered-by {display: flex; color: black; text-decoration: none; font-size: 10px; background: white; border: solid grey 1px; width: 160px; padding: 0.3rem 0.6rem; margin-top: 5px; margin-left: 0; border-radius: 30px; align-items: center;}
  #snapcell-logo {height: 14px; background: white; padding: 0px 6px; border-radius: 10px; padding-right: 10px; }
  #prompt {color: #fff; padding-left: 5px; padding-right: 5px; line-height: 16px; padding-bottom: 10px; font-weight: 400; font-size: 14px; }
  #store_name {color: #fff; padding-left: 5px; padding-right: 5px; font-size: 20px !important; line-height: 24px; }
  #error_form {color: red; padding-left: 5px; padding-right: 5px; line-height: 16px; padding-bottom: 5px; font-weight: 400; font-size: 14px; display: none; background: white; border-radius: 5px; margin: 0 9px; padding-top: 5px;}
  .snapcell_show {opacity: 1 !important; transition: opacity 1s;}
  .snapcell_none {display: none !important;}
  .snapcell_hide {opacity: 0 !important; transition: opacity 0.2s;}
  .snapcell_prompt_message_position_left {position: fixed; bottom: 120px; left: 15px;}
  .snapcell_prompt_message_position_right {position: fixed; bottom: 90px; right: 20px;}
  .snapcell_prompt_message_position_left .snapcell_prompt_card:after { bottom: -6px; left: 18px;}
  .snapcell_prompt_message_position_right .snapcell_prompt_card:after { bottom: -6px; right: 18px;}
  .snapcell_prompt_message_bubble {opacity: 0; font-family: 'Source Sans Pro';}
  .snapcell_prompt_card { display: flex; flex-direction: row; position: relative; background: #fff; border: 1px solid #e8e9eb; border-radius: 13px; cursor: pointer; padding: 24px 19px; z-index: 99999; box-shadow: 0 8px 20px 0 rgb(0 0 0 / 24%); width: 160px !important; font-weight: 600;}
  .snapcell_prompt_card:after {content: ''; width: 20px; height: 20px; position: absolute; background: #fff; border-radius: 4px; z-index: -1; -webkit-transform: rotate(45deg); transform: rotate(45deg);}
  .snapcell_prompt_close_button {display: flex; justify-content: flex-end;}
  .snapcell_prompt_close_button button { border-radius: 99999px; border: none; color: #767676; cursor: pointer; font-size: 12px; font-weight: 500; padding: 5px 10px; margin-right: 3px; margin-bottom: 4px;}
  #snapcell_widget .select2 {display: none !important;}
  #snapcell_widget select {display: block; opacity: 1 !important; visibility: visible !important;}
  .snapcell_input_content_100 { width: 100% !important; margin-top: 20px; }
  .snapcell_input_content { position: relative; height: 32px; width: 190px; font-family: 'Source Sans Pro'; color: #333; }
  .snapcell_input_content input { border: 0px; outline: none; height: 20px; width: 100%; font-size: 13px; padding: 5px 0 0 0; }
  .snapcell_input_content label { position: absolute; left: 0px; top: 0px; cursor: text; transition: all .2s; font-size: 13px; }
  .snapcell_input_content.snapcell_selected label { font-size: 11px; top: -11px; color:#fc7714; }
  .snapcell_input_content .snapcell_input_border { position: relative; display: flex; justify-content: center; }
  .snapcell_input_content .snapcell_input_border::before { content: ''; height: 1px; position: absolute; width: 100%; background-color: #98a3ae; }
  .snapcell_input_content .snapcell_input_border::after { content: ''; height: 2px; position: absolute; transition: .2s ease; width: 0; background-color: ${defaultColor}; }
  .snapcell_input_content.snapcell_selected .snapcell_input_border::after { width: 100%; }
  .snapcell_input_content.snapcell_error label { color: #ef5350; }
  .snapcell_input_content.snapcell_error .snapcell_input_border::before { background-color: #ef5350; }
  .snapcell_form_content { display: flex; align-items: center; background-color: white; padding: 10px 20px 32px 20px; border-radius: 24px 24px 3px 24px; width: 100%; border-bottom: 1px solid #dddbdb }
  .snapcell_form_content--initial { flex-direction: column; }
  .snapcell_form_content_small { padding: 5px 0px; display: flex; width: 100%; border-radius: 0px; justify-content: center; }
  .snapcell_form_content_small .snapcell_input_content { width: 100%; margin-left: 20px; }
  .snapcell_form_content.snapcell_chat_content { margin: 30px 0 15px; padding: 30px 20px 52px 20px; }
  .snapcell_headline { font-family: 'Source Sans Pro'; background-color: ${defaultColorRgb}; border-radius: 24px 24px 24px 3px; font-weight: 400; font-size: 14px; display: flex; padding: 20px; justify-content: center; align-items: center; color: white; margin: 40px 10px 10px; box-shadow: 1px 3px 10px 1px rgb(0 0 0 / 16%); }
  .snapcell_message_item_from { transition: transform 500ms cubic-bezier(0, 1.4, 1, 1); font-family: 'Source Sans Pro'; background-color: white; border-radius: 24px 24px 3px 24px; font-weight: 400; font-size: 14px; display: flex; padding: 20px; justify-content: center; align-items: center; color: #4c5667; margin: 10px; float: right; clear: both; box-shadow: 1px 3px 10px 1px rgb(0 0 0 / 16%); flex-direction: column; }
  .snapcell_message_item_to { transition: transform 500ms cubic-bezier(0, 1.4, 1, 1); font-family: 'Source Sans Pro'; background-color: #ffcaa3; border-radius: 24px 24px 24px 3px; font-weight: 400; font-size: 14px; display: flex; padding: 20px; justify-content: center; align-items: center; color: white; margin: 10px; float: left; clear: both; box-shadow: 1px 3px 10px 1px rgb(0 0 0 / 16%); flex-direction: column; }
  .snapcell_message_item_hr_from { font-family: 'Source Sans Pro'; font-weight: bold; font-size: 12px; display: flex; justify-content: center; align-items: center; color: #4c5667; margin: 0px 14px 10px 14px; float: right; clear: both;}
  .snapcell_message_item_hr_to { font-family: 'Source Sans Pro'; font-weight: bold; font-size: 12px; display: flex; justify-content: center; align-items: center; color: #4c5667; margin: 0px 14px 10px 14px; float: left; clear: both;}
  .snapcell_message_item_sender_from { transition: transform 500ms cubic-bezier(0, 1.4, 1, 1); font-family: 'Source Sans Pro'; font-weight: bold; font-size: 12px; display: flex; justify-content: center; align-items: center; color: #4c5667; margin: 8px 14px 0px 14px; float: right; clear: both;}
  .snapcell_message_item_sender_to { transition: transform 500ms cubic-bezier(0, 1.4, 1, 1); font-family: 'Source Sans Pro'; font-weight: bold; font-size: 12px; display: flex; justify-content: center; align-items: center; color: #4c5667; margin: 8px 14px 0px 14px; float: left; clear: both;}
  .snapcell_header { font-family: 'Source Sans Pro'; background-color: #fc7714; color: white; width: 100%; height: 60px; border-radius: 20px 20px 0  0; font-size: 14px; display: flex; align-items: center; justify-content: center; font-weight: bold; }
  .snapcell_main_content { width: 100%; height: 440px; overflow-y: auto; overflow-x: hidden; display: flex; flex-direction: column; background-color: #f5f5f7; justify-content: space-between; }
  .snapcell_powered_by { display: flex; text-decoration: none; font-size: 10px; background: white; padding: 0.3rem 0.6rem; margin-top: 5px; margin-left: 0; border-radius: 30px; justify-content: center; color: #8c8f8f; font-family: 'Source Sans Pro'; }
  #snapcell-logo { height: 14px; position: relative; top: -1px; background: white; padding: 0px 6px; border-radius: 10px; padding-right: 10px; }
  .snapcell_footer { font-family: 'Source Sans Pro'; border-radius: 0  0 15px 15px; background-color: white; width: 100%; padding: 4px 0px; }
  .snapcell_content { box-shadow: 1px 3px 10px 1px rgb(0 0 0 / 50%); border-radius: 20px; }
  .snapcell_button_actions { display: flex; justify-content: center; align-items: center; flex-wrap: wrap; }
  .snapcell_button_actions_sms { display: flex; justify-content: center; align-items: center; flex-wrap: wrap; margin-top: 10px; }
  .snapcell_button_actions_sms input[type='button'] { background: ${defaultColor}; border: 0px; color: white; border-radius: 7px; padding: 9px 6px; font-weight: bold; margin: 5px 2px; font-size: 11px; cursor: pointer; }
  .snapcell_button_actions input[type='button'] { background: #fc7714; border: 0px; color: white; border-radius: 7px; padding: 13px; font-weight: bold; margin: 14px; font-size: 11px; cursor: pointer; margin-right: 3px; }
  .snapcell_button_actions input[type='submit'] { background: #fc7714; border: 0px; color: white; border-radius: 7px; padding: 9px 6px; font-weight: bold; margin: 5px; font-size: 11px; cursor: pointer; }
  .snapcell_button_submit button {background: none; border: 0; width: 25px; cursor: pointer; transition: all .2s ease-in-out; margin-right: 20px;}
  .snapcell_button_submit button:hover {transform: scale(1.1);}
  .snapcell_text_{ color: #8c8f8f; font-size: 11px; padding: 5px 10px 10px 10px; text-align: center; }
  .snapcell_sms_video_link, .snapcell_sms_link, .snapcell_mess_link {font-size: 11px; color: white; background: ${defaultColor}; padding: 12px; border-radius: 7px; text-decoration: none; font-family: 'Source Sans Pro'; font-weight: 600; margin: 0 3px 0 0;}
  .snapcell_box.snapcell_sms_link, .snapcell_box.snapcell_sms_video_link { align-items: center; display: flex; flex-direction: row; width: 100%; transition: .2s all; border-radius: 10px;}
  .snapcell_box.snapcell_sms_link .snap_cell_icon, .snapcell_box.snapcell_sms_video_link .snap_cell_icon {transition: .2s all; width: 19px; display: block; fill: ${defaultColor}; background: white; padding: 6px; border-radius: 50%;}
  .snapcell_box.snapcell_sms_link .snap_cell_text, .snapcell_box.snapcell_sms_video_link .snap_cell_text {font-size: 14px; font-family: 'Source Sans Pro'; font-weight: normal; margin: 0 11px;}
  .snapcell_box.snapcell_sms_link:hover, .snapcell_box.snapcell_sms_video_link:hover { background: white; }
  .snapcell_box.snapcell_sms_link:hover .snap_cell_icon, .snapcell_box.snapcell_sms_video_link:hover .snap_cell_icon {fill: white; background: ${defaultColor};}
  .snapcell_box.snapcell_sms_link:hover .snap_cell_text, .snapcell_box.snapcell_sms_video_link:hover .snap_cell_text {color: ${defaultColor};}
  .snapcell_box {margin: 6px; 0}
  .snapcell_select { display: none; width: 183px; padding: 5px 0px; margin: 8px 0px 0 !important; border: 0; border-radius: 4px; text-transform: capitalize; font-size: 12px;}
  .snapcell_message_content_success_error { display: flex; font-family: 'Source Sans Pro'; justify-content: center; align-items: center; font-size: 14px; display: none; width: 80%;}
  .snapcell_or_chat { font-family: 'Source Sans Pro'; font-size: 12px; font-weight: 600; }
  .snapcell_loader { display: none; border: 2px solid #f3f3f3; border-top: 2px solid ${defaultColor}; border-radius: 50%; width: 8px; height: 8px; animation: snapcell_spin 2s linear infinite; }
  @keyframes snapcell_spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }
  .snapcell_button_actions button { background: ${defaultColor}; border: 0px; color: white; border-radius: 7px; padding: 12px; font-weight: bold; font-size: 11px; cursor: pointer; display: flex; justify-content: center; align-items: center; min-width: 86px; height: 14px; margin: 5px 2px; }
  .snapcell_button_actions_sms button { background: ${defaultColor}; border: 0px; color: white; border-radius: 7px; padding: 12px; font-weight: bold; font-size: 11px; cursor: pointer; display: flex; justify-content: center; align-items: center; min-width: 86px; height: 14px; margin: 5px 2px; }
  .snapcell_message_content { width: 100%; }
  #snapcell_message_chat { margin-bottom: 0;}
  #snapcell_content_chat { width: 300px; }
  #snapcell_message_sent, #snapcell_sms_video_sent, #snapcell_select_location { margin: 0 30px; }
  #snapcell_message_sent, #snapcell_sms_video_sent, #snapcell_select_location { display: flex; align-items: center; flex-direction: column; }
  #snapcell_message_sent { justify-content: center; height: 100%; }
  .snapcell_message_holder { display:flex; align-items: center; flex-direction: column; }
  button:hover, a:hover, .snapcell-chat-close:hover { opacity: 0.8; }
  .snapcell_answers, .snapcell_buttons {transition: transform 500ms cubic-bezier(0, 1.4, 1, 1); clear: both; display: flex; align-items: center; justify-content: center; flex-wrap: wrap;}
  .snapcell_answer, .snapcell_button { margin: 5px 10px; background: white; border: 0px; color: ${defaultColor}; padding: 10px 20px; border-radius: 20px; min-width: 60px; cursor: pointer; border: 2px solid ${defaultColor}};
  .snapcell_answer:hover, .snapcell_button:hover { background: ${defaultColor}; color: white; border: 2px solid ${defaultColor};}
  .snapcell_element_link { color: black; }
  .snapcell_close_button { border-radius: 50%; display: none; width: 100%; height: 100%; align-items: center; justify-content: center;}
  .snapcell_close_button img {transform: rotate(90deg) scale(0); transition: transform 500ms cubic-bezier(0, 1.4, 1, 1); height: 40px;}
  .scale_2 img {transform: rotate(360deg) scale(1);}
  .change_direction_from { transform: translate(3em,0); }
  .change_direction { transform: translate(0,0); }
  #icon-color {fill: ${defaultColor}} "#snapcell_header_icon { position: relative; width: 50px; height: 50px; background-size: cover; border-radius: 50%; margin-right: 10px; display: inline;}
  #snapcell_header_icon:after { display: block; content: ''; position: absolute; right: 3px; bottom: 0; width: 10px; height: 10px; border-radius: 50%; background-color: #03c303; z-index: 99999;}
  .sender-content { display: flex; align-items: center; }
  .sender-img { width: 20px; height: 20px; border-radius: 50%; margin-right: 5px; }
  .snapcell_message_loading{height:10px; margin-top: 1px; margin-bottom: 4px}
  .dot-pulse{position:relative;left:-9980px;width:10px;height:10px;border-radius:5px;box-shadow:9999px 0 0 -5px ${defaultColor};animation:dotPulse 1.5s infinite linear;animation-delay:.25s}
  .dot-pulse::before,.dot-pulse::after{content:'';display:inline-block;position:absolute;top:0;width:10px;height:10px;border-radius:5px}
  .dot-pulse::before{box-shadow:9984px 0 0 -5px ${defaultColor};animation:dotPulseBefore 1.5s infinite linear;animation-delay:0}
  .dot-pulse::after{box-shadow:10014px 0 0 -5px ${defaultColor};animation:dotPulseAfter 1.5s infinite linear;animation-delay:.5s}
  @keyframes dotPulseBefore{0%{box-shadow:9984px 0 0 -5px ${defaultColor}}30%{box-shadow:9984px 0 0 2px ${defaultColor}}60%,100%{box-shadow:9984px 0 0 -5px ${defaultColor}}}
  @keyframes dotPulse{0%{box-shadow:9999px 0 0 -5px ${defaultColor}}30%{box-shadow:9999px 0 0 2px ${defaultColor}}60%,100%{box-shadow:9999px 0 0 -5px ${defaultColor}}}
  @keyframes dotPulseAfter{0%{box-shadow:10014px 0 0 -5px ${defaultColor}}30%{box-shadow:10014px 0 0 2px ${defaultColor}}60%,100%{box-shadow:10014px 0 0 -5px ${defaultColor}}}
  .request-telephone {display: flex;flex-direction: column}
  .request-telephone-input { margin-bottom: 4px; margin-top: 4px; border-radius: 20px; border: 3px solid #f29052; padding: 0.5rem 1rem;}
  .request-telephone-input, input:focus { outline: none; }
  .request-telephone-button { border: 1px solid #f29052; border-radius: 20px; background-color: white; color: ${defaultColor}; cursor: pointer; height: 20px; margin-top: 3px;}
  .request-telephone-button:hover { color: white; border: 1px solid white; background-color: ${defaultColor}; }
  .optin { color: #8c8f8f !important; font-size: 11px !important; line-height: 1.25em !important; display: inline !important; font-style: normal !important; font-family: 'Source Sans Pro';}
  .opt_store { font-weight: normal !important; padding: 0 !important; font-size: 12px !important; line-height: 1.5em !important; display: inline !important; font-style: normal !important; }
  `;
};
