import { debugMessage } from "./pluginUtil";

declare global {
  interface Window {
    utag?: {
      link: (data: { tealium_event: "identity_event"; customer_email: string; customer_phone: string; customer_first_name: string; customer_last_name: string }) => void;
    };
  }
}

async function sha256(str: string) {
  const buf = await crypto.subtle.digest("SHA-256", new TextEncoder().encode(str));
  return Array.prototype.map.call(new Uint8Array(buf), (x) => x.toString(16).padStart(2, "0")).join("");
}

// Uncomment to test locally
// window.utag = {
//  link: (args) => {
//    console.log("Tealium call: ", args);
//  },
// };

/**
 * To call Tealium:
 *
 * utag.link ({
 *   "tealium_event" : 'identity_event",
 *   "customer_email" : “{{email}}”, //lowercased and trimmed, then SHA256 hashed*
 *   "customer_phone" : “{{phone}}”, //digits only (1-9), no country code, then SHA256 hashed*
 *   "customer_first_name" : “{{firstName}}”, //lowercased and all whitespace/special chars removed, then SHA256 hashed*
 *   "customer_last_name" : “{{lastName}}” //lowercased and all whitespace/special chars removed, then SHA256 hashed*
 * })
 * @param customer
 */
export async function sendCustomerToTealium(customer: { email?: string; phone?: string; firstName?: string; lastName?: string }) {
  if (window.utag) {
    const params = { ...customer };

    params.firstName = params.firstName?.replace(/[^a-zA-Z0-9]/g, "") || "";
    params.lastName = params.lastName?.replace(/[^a-zA-Z0-9]/g, "") || "";
    params.phone = params.phone?.replace(/\D/g, "") || "";
    params.email = params.email || "";

    for (const key of Object.keys(params)) {
      let val = params[key];
      val = val.toLowerCase().trim();
      debugMessage(`Hashing ${key} ${val}`);
      params[key] = await sha256(val);
    }

    window.utag.link({
      tealium_event: "identity_event",
      customer_email: params.email,
      customer_phone: params.phone,
      customer_first_name: params.firstName,
      customer_last_name: params.lastName,
    });
  }
}
