// Sends a badge_view analytics when has badge has been viewed for a certain period of time

import { logEvent } from "../lib/analytics";

const TRIGGER_VIEW_TIME = 1500; // number of ms a superlative has to be visible before triggering event

const supObsSet = new Set<string>(); // vin + superlative
const supObserver = new IntersectionObserver(
  (entries, b) => {
    entries.forEach((entry) => {
      const vin = entry.target.getAttribute("data-vin");
      const superlative = entry.target.getAttribute("data-superlative");
      const superlative_id = entry.target.getAttribute("data-superlative-id");
      const count = entry.target.getAttribute("data-count");

      const key = vin + superlative;
      if (entry.intersectionRatio === 1) {
        // badge has completely scrolled into view
        supObsSet.add(key);
        setTimeout(() => {
          // If the badge has scrolled out of view before the timeout has been reached,
          // it will have been deleted from supObsSet and we therefore won't record the event.
          if (supObsSet.has(key)) {
            supObsSet.delete(key);
            supObserver.unobserve(entry.target);
            logEvent("badge_view", { vin, superlative_id, superlative, count });
          }
        }, TRIGGER_VIEW_TIME);
      } else if (entry.intersectionRatio < 0.8) {
        // badge has started to scroll out of view
        supObsSet.delete(key);
      }
    });
  },
  { threshold: [0.75, 1.0] }
);

function trackSuperlativeView(element: Element) {
  if (supObserver) {
    supObserver.observe(element);
  }
}

export { trackSuperlativeView };
