import { Analytics, initializeAnalytics, isSupported, logEvent as fbLogEvent, settings, setUserProperties } from "firebase/analytics";
import { initializeApp } from "firebase/app";

declare global {
  interface Window {
    Promise: typeof Promise;
  }
}

// Some scripts overwrite native Promise with bluebird or polyfill.
// Keep a ref to Promise at the time our script initially loads (hopefully native) so we can swap it back in
const origPromise = window.Promise;

let analytics: Analytics = null;

type Event = Parameters<typeof fbLogEvent>[1];
type EventParams = Parameters<typeof fbLogEvent>[2];

const eventContext = new Map<Event, EventParams>();
let dealerId;

export async function initFirebase(dealer_id: string, plugin_location) {
  if (!(await isSupported()) || analytics) {
    return;
  }

  dealerId = dealer_id;

  const firebaseConfig = {
    apiKey: "AIzaSyBprnaUvHwAQaVkME0vqjnHhNd_p0V-r04",
    authDomain: "snap-plugin-prod.firebaseapp.com",
    projectId: "snap-plugin-prod",
    storageBucket: "snap-plugin-prod.appspot.com",
    messagingSenderId: "783443811837",
    appId: "1:783443811837:web:888b4a983b12c925e7cd94",
    measurementId: "G-GH9FXE8RZM",
  };

  // FB has issues with non-native Promise libraries, so try to swap it back in case it was replaced
  window.Promise = origPromise;

  // If bluebird promises have been loaded globally (replacing window.Promise),
  // revert it back to native Promise to avoid problems with firebase.
  if ((Promise as any).noConflict) {
    (Promise as any).noConflict();
  }

  // If still not native promise, try this
  if (window.Promise.toString().indexOf("native") < 0) {
    const iframe = document.createElement("iframe");
    iframe.id = "tp-analytics-temp";
    iframe.style.display = "none";
    document.body.appendChild(iframe);
    if (iframe.contentWindow.Promise) {
      window.Promise = iframe.contentWindow.Promise;
    }
  }

  const app = initializeApp(firebaseConfig, "snap");

  // Change gtag name used by firebase to keep it from interfering with other copies of gtag
  settings({ gtagName: "tp_gtag", dataLayerName: "tp_datalayer" });

  const fa = initializeAnalytics(app, { config: { location: plugin_location, dealer_id } });
  setUserProperties(fa, { dealer_id });
  analytics = fa;
}

/** This will only log the event to firebase  */
export function logEvent(event: Event, params?: EventParams) {
  if (!analytics) {
    console.warn("Analytics not initialized");
  } else {
    const context = eventContext.get(event);
    let eventParams = { did: dealerId, ...params };
    if (eventContext.has(event)) {
      eventParams = { ...eventParams, ...context };
    }
    fbLogEvent(analytics, event, eventParams);
  }
}

// Add some context for a particular analytics event.
// Whenever that event is logged, the extra context will be merged into the parameters
export function addEventContext(event: Event, key: string, value: any) {
  const params: EventParams = {};
  params[key] = value;
  if (!eventContext.has(event)) {
    eventContext.set(event, params);
  } else {
    eventContext.set(event, { ...eventContext.get(event), ...params });
  }
}
